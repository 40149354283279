import { Checkbox, CheckboxVisibility, CommandBar, DefaultButton, FontWeights, IButtonStyles, IColumn, IIconProps, IconButton, Modal, Panel, PanelType, PrimaryButton, SelectionMode, Stack, Text, getTheme, mergeStyleSets } from '@fluentui/react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { FC, useEffect, useState } from 'react';
import { AuthenticatedTemplate, useAccount, useIsAuthenticated, useMsal, useMsalAuthentication } from '@azure/msal-react';
import { Membership } from '../../model/Membership';
import { getMembership, getViralJoinEnv, postStudentUpdate, postStudentToMembership, getUserProfile, updateUserProfile, readInputs, writeInputs, updateEmailNotificationPreference, updateMobileNotificationPreference } from '../../ApiService';
import { useBoolean } from '@fluentui/react-hooks';
import { Program } from '../../model/Program';
import { Level } from '../../model/Level';
import StudentEditor from '../../components/StudentEditor';
import { Student } from '../../model/Student';
import Loader from '../../components/Loader';
import { InteractionType } from '@azure/msal-browser';
import { UserProfile } from '../../model/UserProfile';
import List, { IDocument } from '../../components/List';
import UserProfileEditor from './components/UserProfileEditor';
import { useNavigate } from 'react-router-dom';
import { IMembershipInput, IUserInputWithStudents } from '../../model/CatalogItem';
import { GetPhoneDisplayString } from '../../Display';

interface IStudentDocument extends IDocument {    
    studentName: string;
    studentLevel: string;
    dateOfBirth: Date;
}

const EditProfile: FC = () => {
    const appInsights = useAppInsightsContext();
    appInsights?.trackPageView({ name: "Edit Profile Page" });
    
    useMsalAuthentication(InteractionType.Redirect, { scopes: ['openid profile', 'offline_access'], state: document.location.href });
    
    const navigate = useNavigate();

    const { instance, accounts, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const account = useAccount(accounts[0] || {});
    const [membership, setMembership] = useState<Membership>();
    const [userProfile, setUserProfile] = useState<UserProfile>();
    const [isEditOpen, { setTrue: openEditPanel, setFalse: dismissEditPanel }] = useBoolean(false);
    const [isEditUserOpen, { setTrue: openEditUserPanel, setFalse: dismissEditUserPanel }] = useBoolean(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [allPrograms, setAllPrograms] = useState<Program[]>([]);
    const [allLevels, setAllLevels] = useState<Level[]>([]);
    const [requestStudentGender, setRequestStudentGender] = useState<boolean>(false);
    const [requestStudentDob, setRequestStudentDob] = useState<boolean>(false);
    const [requestStudentWeight,] = useState<boolean>(false);
    const [requestStudentHeight, ] = useState<boolean>(false);
    const [customValues, setCustomValues] = useState<IUserInputWithStudents[]>();
    
    
    useEffect(() => {
        const fetchAsync = async () => {
            if (inProgress === "none" && account) {
                var membershipResult = await getMembership(instance, account);

                if (membershipResult.StatusCode === 200) {
                    var env = await getViralJoinEnv();
                    var profile = await getUserProfile(instance, account!);
                    var customValues = await readInputs(instance, account!);
                    
                    var programs = env!.Programs;
                    var levels = env!.Levels
                    
                    if (env !== undefined) {
                        setRequestStudentDob(env?.RequestStudentDob);
                        setRequestStudentGender(env?.RequestStudentGender);
                        //TODO: include weight here
                    }
    
                    if (typeof customValues !== 'number') {
                        setCustomValues(customValues);
                    }
                    
                    setAllPrograms(programs);
                    setAllLevels(levels);
                    setMembership(membershipResult.Result!);
                    setUserProfile(profile);
                }

                setIsLoading(false);
            }
        }
        
        fetchAsync();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, inProgress, account]);

    const [diffStudent, setDiffStudent] = useState<Student>();
    const [editStudent, setEditStudent] = useState<Student>();
    const [isEditValid, setIsEditValid] = useState<boolean>(false);
    const [hasStudentChange, setHasStudentChange] = useState<boolean>(false);
    const [hasUserChange, setHasUserChange] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isCreate, setIsCreate] = useState<boolean>(false);
    const [selectedStudent, setSelectedStudent] = useState<Student>();

    const openStudentEditor = (student?: Student) => {
        if (student === undefined) {
            setIsCreate(true);
            student = new Student();
        }
        else {
            setIsCreate(false);
        }

        let diffStudent = JSON.parse(JSON.stringify(student)) as Student;
        diffStudent.DateOfBirth = student?.DateOfBirth; //avoid serialisation sillies
        
        setDiffStudent(diffStudent);
        setEditStudent(student);
        openEditPanel();
    }

    const [diffUser, setDiffUser] = useState<UserProfile>();
    const [editUser, setEditUser] = useState<UserProfile>();
    const [diffMembership, setDiffMembership] = useState<Membership>();
    const [editMembership, setEditMembership] = useState<Membership>();

    const openUserEditor = (student?: Student) => {
        setDiffUser(JSON.parse(JSON.stringify(userProfile)));
        setDiffMembership(JSON.parse(JSON.stringify(membership)));

        openEditUserPanel();
    }

    useEffect(() => {
        if (editStudent?.DateOfBirth?.getFullYear() !== diffStudent?.DateOfBirth?.getFullYear() ||
            editStudent?.DateOfBirth?.getMonth() !== diffStudent?.DateOfBirth?.getMonth() ||
            editStudent?.DateOfBirth?.getDate() !== diffStudent?.DateOfBirth?.getDate()) {            
            setHasStudentChange(true);
            return;
        }

        if (editStudent?.Weight !== diffStudent?.Weight) {
            setHasStudentChange(true);
            return;
        }

        if (editStudent?.Height !== diffStudent?.Height) {
            setHasStudentChange(true);
            return;
        }

        if (editStudent?.FirstName !== diffStudent?.FirstName) {
            setHasStudentChange(true);
            return;
        }

        if (editStudent?.Gender !== diffStudent?.Gender) {
            setHasStudentChange(true);
            return;
        }
        
        if (editStudent?.ImageUri !== diffStudent?.ImageUri) {
            setHasStudentChange(true);
            return;
        }
        
        if (editStudent?.LastName !== diffStudent?.LastName) {
            setHasStudentChange(true);
            return;
        }
        
        if (editStudent?.LevelId !== diffStudent?.LevelId) {
            setHasStudentChange(true);
            return;
        }

        if (editStudent?.SpecialNeeds !== diffStudent?.SpecialNeeds) {
            setHasStudentChange(true);
            return;
        }

        //check custom fields also
        if (customValues !== undefined && editStudent !== undefined) {            
            //need to find all custom fields that are for this student
            let studentCustomFields = customValues.filter(cf => cf.StudentIds.indexOf(editStudent?.Id) > -1);
            
            for (var s of studentCustomFields) {
                let studentCustomField = s;
                
                let diffValue = diffStudent?.CustomValues?.find(cf => cf.Key === studentCustomField.BindTo.replace("x:", ""))?.Value;
                let editValue = editStudent?.CustomValues?.find(cf => cf.Key === studentCustomField.BindTo.replace("x:", ""))?.Value;                

                if (diffValue !== editValue) {
                    setHasStudentChange(true);
                    return;
                }
            }
        }
        
        setHasStudentChange(false);
    }, [editStudent, diffStudent, customValues]);

    useEffect(() => {
        if (editUser?.DisplayName !== diffUser?.DisplayName) {
            setHasUserChange(true);
            return;
        }

        if (editUser?.Email !== diffUser?.Email) {
            setHasUserChange(true);
            return;
        }

        if (editUser?.Mobile !== diffUser?.Mobile) {
            setHasUserChange(true);
            return;
        }

        if (editMembership?.TrainingFacilityTitle !== diffMembership?.TrainingFacilityTitle) {
            setHasUserChange(true);
            return;
        }

        setHasUserChange(false);
    }, [editUser, diffUser, editMembership, diffMembership]);

    const studentValidationStatusChanged = (student: Student, isValid: boolean) => {
        setEditStudent(() => student === undefined ? undefined : ({ ...student!, CustomValues: student!.CustomValues }));
        setIsEditValid(isValid);
    }

    const userValidationStatusChanged = (userProfile: UserProfile, membership: Membership, isValid: boolean) => {
        setEditUser(() => userProfile === undefined ? undefined : ({ ...userProfile! }));
        setEditMembership(membership);
        setIsEditUserValid(isValid);
    }

    const saveStudent = async () => {
        setIsSaving(true);

        if (isCreate) {
            var addedStudent = await postStudentToMembership(instance, account!, editStudent!);

            if (addedStudent === undefined) {
                alert("Sorry, something went wrong.");
            }
            else {
                var students = membership!.Students;
                students.push(addedStudent);

                setMembership(membership => ({ ...membership!, Students: students }));
            }
        }
        else {
            //were custom values modified?
            let inputsChanged: IMembershipInput[] = [];

            if (customValues !== undefined && editStudent !== undefined) {
                //need to find all custom fields that are for this student
                let studentCustomFields = customValues.filter(cf => cf.StudentIds.indexOf(editStudent?.Id) > -1);

                for (var s of studentCustomFields) {
                    let studentCustomField = s;

                    let diffValue = diffStudent?.CustomValues?.find(cf => cf.Key === studentCustomField.BindTo.replace("x:", ""))?.Value;
                    let editValue = editStudent.CustomValues?.find(cf => cf.Key === studentCustomField.BindTo.replace("x:", ""))?.Value;

                    if (editValue === undefined) {
                        continue;
                    }

                    if (diffValue !== editValue) {
                        inputsChanged.push({
                            StudentId: editStudent.Id,
                            CustomValues: [{
                                Key: studentCustomField.BindTo.replace("x:", ""),
                                Value: editValue
                            }]
                        });
                    }
                }
            }

            if (inputsChanged.length > 0) {
                var writeInputResult = await writeInputs(instance, account!, inputsChanged);    

                if (writeInputResult?.status === 409) {
                    alert("This student is registered for an event in a tournament that has not yet ended. Values cannot be updated while the tournament is active.")    
                    dismissEditPanel();
                    setIsSaving(false);
                    setIsCreate(false);
                    setIsEditValid(false);
                    setHasStudentChange(false);
                    return;
                }

                if (!writeInputResult?.ok) {
                    alert("Sorry, something went wrong.");
                }
            }            

            //TODO: calculate if this also needs to be updated
            var studentUpdateResult = await postStudentUpdate(instance, account!, editStudent!);
            
            if (studentUpdateResult?.status === 409) {
                alert("This student is registered for an event in a tournament that has not yet ended. Values cannot be updated while the tournament is active.")    
                dismissEditPanel();
                setIsSaving(false);
                setIsCreate(false);
                setIsEditValid(false);
                setHasStudentChange(false);
                return;
            }

            if (!studentUpdateResult?.ok) {
                alert("Sorry, something went wrong.");
            }
            else {
                //update the local student model with the new value
                let students = membership!.Students;
                students.splice(students.findIndex(s => s.Id === editStudent?.Id), 1, editStudent!);
                setMembership(membership => ({ ...membership!, Students: students }));
            }
        }

        dismissEditPanel();
        setIsSaving(false);
        setIsCreate(false);
        setIsEditValid(false);
        setHasStudentChange(false);
    }

    const listItems: IStudentDocument[] = [];

    membership?.Students.forEach((student, idx) => {
        listItems.push({
            key: student.Id,
            studentName: `${student.FirstName} ${student.LastName}`,
            studentLevel: student.Level?.Label!,
            dateOfBirth: student.DateOfBirth!,
            getTitle: () => `${student.FirstName} ${student.LastName}`,
        })
    });

    const [columns,] = useState<IColumn[]>([
        {
            key: '1',
            name: 'Name',
            fieldName: 'studentName',
            minWidth: 100,
            maxWidth: 250,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,
        },
        {
            key: '2',
            name: 'Date of birth',
            fieldName: 'dateOfBirth',
            minWidth: 100,
            maxWidth: 250,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,
            onRender: (item: IStudentDocument) => {
                return <span>{item.dateOfBirth.toLocaleDateString()}</span>
            }
        },
        {
            key: '3',
            name: 'Level',
            fieldName: 'studentLevel',
            minWidth: 100,
            maxWidth: 250,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,
        },
    ]);

    const [isEditUserValid, setIsEditUserValid] = useState<boolean>(false);
    const saveUser = async () => {
        setIsSaving(true);

        let result = await updateUserProfile(instance, account!, editUser!, editMembership?.TrainingFacilityId, editMembership?.TrainingFacilityTitle);

        if (!result) {
            alert("Sorry, something went wrong and may not have been able to save your changes.");
            return;
        }
        else {            
            setUserProfile(editUser);
            setMembership(editMembership);
        }

        dismissEditUserPanel();
        setIsSaving(false);
        setIsEditUserValid(false);
        setHasUserChange(false);
    }

    const cancelIcon: IIconProps = { iconName: 'Cancel' };

    const theme = getTheme();
    const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
        maxWidth: 500        
    },
    header: [
        theme.fonts.xLarge,
        {
        flex: '1 1 auto',
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
        },
    },
    });
    
    const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
    };

    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);

    const updateEmailNotifications = async (enabled:boolean) => {
        var success = await updateEmailNotificationPreference(instance, account!, enabled);        

        if (!success) {
            alert("Sorry, something went wrong. Please try again.");
            return;
        }

        setUserProfile(userProfile => ({ ...userProfile!, NotifyEmail: enabled }));
    }

    const updateMobileNotifications = async (enabled:boolean) => {
        var success = await updateMobileNotificationPreference(instance, account!, enabled);        

        if (!success) {
            alert("Sorry, something went wrong. Please try again.");
            return;
        }

        setUserProfile(userProfile => ({ ...userProfile!, NotifyMobile: enabled }));
    }

    return (
        <AuthenticatedTemplate>
            <div style={{ padding: 20 }}>
                <Stack tokens={{ childrenGap: 20 }}>
                    <Text variant='xLarge'>User Profile</Text>
                    {isLoading ? <Loader Text="Just a moment.." /> :
                        <>
                            <div style={{ backgroundColor: 'white', padding: 20 }}>
                                <Text variant='mediumPlus'>{userProfile?.DisplayName}</Text><br />
                                <Text variant='medium'>{userProfile?.Email}</Text><br />
                                <Text style={{color:userProfile?.Mobile === null || userProfile?.Mobile === undefined || userProfile.Mobile.length === 0 ? 'red' : 'initial'}} variant='medium'>{userProfile?.Mobile === null || userProfile?.Mobile === undefined || userProfile.Mobile.length === 0 ? "No phone supplied" : GetPhoneDisplayString(userProfile.Mobile)}</Text><br />
                                <Text variant='medium'>{membership?.TrainingFacilityTitle}</Text>                                
                                {userProfile?.Mobile === null || userProfile?.Mobile === undefined || userProfile.Mobile.length === 0 ? <><br /><br /><Text variant='smallPlus'>We recommend adding your phone number for event notifications.</Text></> : null}
                                <br /><br />
                                <DefaultButton
                                    style={{ fontSize: 11 }}
                                    onClick={() => openUserEditor()}
                                    iconProps={{ iconName: 'Edit' }}>Edit contact details</DefaultButton>
                                
                                <br />
                                <hr style={{ width: '100%', strokeWidth: 1, color: 'black' }} />
                                <br />
                                <Stack tokens={{childrenGap:10}}>
                                    <Stack horizontal tokens={{childrenGap:10}}>
                                        <Text variant='xLarge'>Notifications</Text>
                                        <IconButton 
                                            styles={{root:{width:15,height:15}}} 
                                            style={{borderRadius:'50%', border:'solid', borderWidth:1, marginTop:2}} 
                                            iconProps={{iconName:'StatusCircleQuestionMark'}} 
                                            onClick={showModal} />
                                    </Stack>
                                    <Checkbox label='Email' defaultChecked={userProfile?.NotifyEmail} onChange={(ev, checked) => updateEmailNotifications(checked!)} />
                                    <Checkbox
                                        disabled={userProfile?.Mobile === null || userProfile?.Mobile === undefined || userProfile.Mobile.length === 0}
                                        label='SMS' defaultChecked={userProfile?.NotifyMobile} onChange={(ev, checked) => updateMobileNotifications(checked!)} />
                                    
                                    {userProfile?.NotifyEmail === false && userProfile?.NotifyMobile === false ?
                                        <Text style={{ color: 'red' }} variant='small'>You will not receive any notifications during an event. We recommend at least one of email or SMS is enabled.</Text> :
                                        null
                                    }
                                </Stack>
                            </div>
                            <Text variant='xLarge'>Members</Text>
                            <Stack>
                            <CommandBar
                                items={[{
                                    key: 'add', text: 'Add', iconProps: { iconName: 'Add' },
                                    onClick: () => openStudentEditor()
                                },
                                {
                                    key: 'edit', text: 'Edit', iconProps: { iconName: 'Edit' },
                                    disabled: selectedStudent === undefined,
                                    onClick: () => openStudentEditor(selectedStudent)
                                }]} />
                               
                            <List
                                items={listItems}
                                columns={columns}
                                selectionMode={SelectionMode.single}
                                checkboxVisibility={CheckboxVisibility.hidden}
                                onActiveItemChanged={(item: IStudentDocument) => { setSelectedStudent(membership!.Students.find(s => s.Id === item.key)!) }} />
                            </Stack>
                            <PrimaryButton style={{minWidth: 300, maxWidth: 500, marginLeft:'auto', marginRight:'auto'}} onClick={() => navigate('/')}>Go Home</PrimaryButton>
                        </>
                    }
                </Stack>
                <Panel
                    headerText={isCreate ? "Add Member" : "Edit Member"}
                    type={PanelType.smallFixedFar}
                    isOpen={isEditOpen}
                    isLightDismiss={true}
                    onDismiss={dismissEditPanel}
                    closeButtonAriaLabel="Close">
                    <StudentEditor                        
                        levels={allLevels}
                        programs={allPrograms}
                        requestDob={requestStudentDob}
                        requestGender={requestStudentGender}
                        requestWeight={requestStudentWeight || (editStudent !== undefined && editStudent.Weight > 0)}
                        requestHeight={requestStudentHeight || (editStudent !== undefined && editStudent.Height > 0)}
                        editStudent={editStudent}
                        customValues={editStudent === undefined ? [] : customValues?.filter(c=>c.StudentIds.indexOf(editStudent.Id) > -1)}
                        onModelValidationStatusChanged={studentValidationStatusChanged}
                    />
                    <br />
                    {isSaving && <Loader Text='Just a moment...' />}
                    <PrimaryButton
                        disabled={!hasStudentChange || isSaving || !isEditValid}
                        onClick={() => saveStudent()}>Save</PrimaryButton>
                </Panel>
                <Panel
                    styles={{scrollableContent:{height:'100%'}}}
                    headerText={"Edit Contact Details"}
                    type={PanelType.smallFixedFar}
                    isOpen={isEditUserOpen}
                    isLightDismiss={true}
                    onDismiss={dismissEditUserPanel}                    
                    closeButtonAriaLabel="Close">
                    <UserProfileEditor
                        userProfile={userProfile!}
                        membership={membership!}
                        onModelValidationStatusChanged={userValidationStatusChanged} />
                    <br />
                    {isSaving && <Loader Text='Just a moment...' />}
                    <PrimaryButton disabled={!isEditUserValid || isSaving || !hasUserChange} onClick={() => saveUser()}>Save</PrimaryButton>
                </Panel>
                    
                <Modal
                    isOpen={isModalOpen}
                    onDismiss={hideModal}
                    containerClassName={contentStyles.container}>
                <div className={contentStyles.header}>
                    <span>What are notifications?</span>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        ariaLabel="Close modal"
                        onClick={hideModal} />
                </div>
                    <div className={contentStyles.body}>
                        <Stack tokens={{childrenGap:10}}>
                            <Text variant="medium">During events that use the Athlete Call feature of NinjaPanel, notifications are sent via SMS and/or email to athletes when it is time to be in a specific location.</Text>
                        </Stack>
                    
                </div>
            </Modal>
            </div>
        </AuthenticatedTemplate>
    )
}

export default EditProfile;